import React from 'react';
import * as Msdyn from '@msdyn365-commerce/core';
import { IBuyboxResources } from '../../definition-extensions/buybox.ext.props.autogenerated';

type BuyBoxModalProps = {
    productTitle?: string;
    productPrice?: string;
    quantity?: number;
    imageFullUrl?: string;
    modalClose?: () => void;
    show?: boolean;
    cartUrl?: string;
    resources: IBuyboxResources & IBuyboxResources;
};

const hiddenStyle: React.CSSProperties = {
    visibility: 'hidden',
    zIndex: 1000,
    display: 'block'
};

const visibleStyle: React.CSSProperties = {
    visibility: 'visible',
    zIndex: 1000,
    display: 'block'
};

const imageGridSettings: Msdyn.IGridSettings = {
    xs: {
        w: 12,
        h: 12
    },
    lg: {
        w: 12,
        h: 12
    },
    md: {
        w: 12,
        h: 12
    },
    sm: {
        w: 12,
        h: 12
    },
    xl: {
        w: 12,
        h: 12
    }
};

const BuyBoxModal = (props: BuyBoxModalProps) => {
    let { productPrice, productTitle, quantity, imageFullUrl, show, modalClose, cartUrl, resources } = props;

    return (
        <div tabIndex={-1} style={show ? visibleStyle : hiddenStyle}>
            <div>
                <div className={`msc-modal ${show ? 'show' : 'fade'}`} style={{ display: 'block' }}>
                    <div className='msc-modal__dialog msc-add-to-cart__dialog' role='dialog' aria-modal='true' tabIndex={0}>
                        <div className='msc-modal__content' role='document'>
                            <div className='msc-add-to-cart__dialog__header msc-modal__header'>
                                <h5 className='msc-modal__title'>
                                    {<span>{resources.buyBoxMultiLineItemFormatText.replace('{0}', `${quantity}`)}</span>}
                                    <span> {resources.buyBoxHeaderMessageText}</span>
                                </h5>
                                <button type='button' onClick={modalClose} className='msc-modal__close-button' aria-label='Close' />
                            </div>
                            <div className='msc-add-to-cart__dialog__body msc-modal__body'>
                                <Msdyn.Image
                                    altText={productTitle}
                                    gridSettings={imageGridSettings}
                                    src={imageFullUrl}
                                    className='msc-main_image msc_image msc-add-to-cart__dialog__image lazyloaded'
                                />
                                <span className='msc-add-to-cart__dialog__product-title'>{productTitle}</span>
                                <div className='msc-add-to-cart__dialog__product-price'>
                                    <span className='msc-price'>
                                        <span className='msc-price__actual' itemProp='price'>
                                            {productPrice}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className='msc-add-to-cart__dialog__footer msc-modal__footer'>
                                <a
                                    href={cartUrl}
                                    aria-label='View bag and checkout'
                                    data-exp-event-id='DefaultPdp.buybox.View bag and checkout.click'
                                    className='msc-add-to-cart__dialog__go-to-cart msc-btn'
                                >
                                    {resources.buyBoxGoToCartText}
                                </a>
                                <button
                                    type='button'
                                    onClick={modalClose}
                                    aria-label='Continue shopping'
                                    data-exp-event-id='DefaultPdp.buybox.Continue shopping.click'
                                    className='msc-add-to-cart__dialog__back-to-shopping msc-btn'
                                >
                                    {resources.buyBoxContinueShoppingText}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`msc-modal__backdrop ${show ? 'show' : 'fade'}`} onClick={modalClose} />
            </div>
        </div>
    );
};

export default BuyBoxModal;
