import React from 'react';

type BuyBoxPriceProps = {
    price: string;
};

const BuyBoxPrice = (props: BuyBoxPriceProps) => {
    const { price } = props;

    return (
        <span className='msc-price'>
            <span className='msc-price__actual'>{price}</span>
        </span>
    );
};

export default BuyBoxPrice;
